import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { useStateContext } from "../../contexts/ContextProvider";
import { MultiSelect } from "react-multi-select-component";

import { loadingOff, loadingOn } from "../../utils/common";
import axiosClient from "../../utils/axios-client";
import routes from "../routes";

import SparrkupLogo from "../../assets/images/logo_secundario.png";
import BaseImg from "../../assets/images/base.png";
import HealthBanner from "../../assets/images/health-banner.jpg";

const languages = {
    fr: "French",
    en: "English",
};

export const SignUp = () => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const { userPhone, retailerCode } = useStateContext();

    const genders = [
        { id: "Male", name: t("page.signUp.gender.male") },
        { id: "Female", name: t("page.signUp.gender.female") },
    ];
    const others = [
        {
            id: t("page.signUp.nicotine.products.shisha", { lng: "en" }),
            name: t("page.signUp.nicotine.products.shisha"),
        },
        {
            id: t("page.signUp.nicotine.products.vape", { lng: "en" }),
            name: t("page.signUp.nicotine.products.vape"),
        },
        {
            id: t("page.signUp.nicotine.products.thps", { lng: "en" }),
            name: t("page.signUp.nicotine.products.thps"),
        },
        {
            id: t("page.signUp.nicotine.products.modern", { lng: "en" }),
            name: t("page.signUp.nicotine.products.modern"),
        },
        {
            id: t("page.signUp.nicotine.products.others", { lng: "en" }),
            name: t("page.signUp.nicotine.products.others"),
        },
    ];
    const othersProducts = {
        Vape: [
            "Vuse",
            "Waza",
            "ElfBar",
            "Yuoto",
            "TugBoat",
            "Geek Bar",
            "Autres / Others",
        ],
        THPs: ["Glo", "iQOS", "Pulze", "PloomTech", "Autres / Others"],
        "Modern Oral": ["Velo", "Nordic Spirit"],
    };
    const tobaccoComms = [
        { id: true, name: t("page.signUp.tobaccoComms.yes") },
        { id: false, name: t("page.signUp.tobaccoComms.no") },
    ];

    const [brands, setBrands] = useState([]);
    const [products, setProducts] = useState([]);
    const [regions, _setRegions] = useState([]);
    const [divisions, _setDivisions] = useState([]);
    const [isTerms, setIsTerms] = useState(false);
    const [consent, setConsent] = useState(false);
    const [terms, setTerms] = useState(false);

    const [user, setUser] = useState({
        cellphone: userPhone ? userPhone : null,
        name: "",
        email: "",
        age: "",
        gender: "",
        brand: "",
        product: "",
        other: "",
        otherBrand: "",
        region: "",
        division: "",
        tobaccoComm: "",
        passionPoint: ""
    });
    const [passionPoint, _setPassionPoint] = useState([]);
    const [valuePassPointSelect, setValuePassPointSelect] = useState('');
    const [valuePassPointOrg, setValuePassPointOrg] = useState('');

    //Geolocation
    const [locationAccepted, setLocationAccepted] = useState(false);
    const [coordinates, setCoordinates] = useState(null);

    // Multiple Selects
    const [socials, setSocials] = useState([]);

    const [nicotineLocations, setNicotineLocations] = useState([]);
    const [nicotineLocationsSelected, setNicotineLocationsSelected] = useState([]);

    const [socialsSelected, setSocialsSelected] = useState([]);

    const [whereBuyCigFrom, setWhereBuyCigFrom] = useState([]);
    const [whereBuyCigFromSelected, setWhereBuyCigFromSelected] = useState([]);

    const [preferredMidia, setPreferredMidia] = useState([]);
    const [preferredMidiaSelected, setPreferredMidiaSelected] = useState([]);

    const [passionPointSport, setPassionPointSport] = useState([]);
    const [passionPointSportSelected, setPassionPointSportSelected] = useState([]);

    const [passionPointMusic, setPassionPointMusic] = useState([]);
    const [passionPointMusicSelected, setPassionPointMusicSelected] = useState([]);

    const [passionPointLifeStyle, setPassionPointLifeStyle] = useState([]);
    const [passionPointLifeStyleSelected, setPassionPointLifeStyleSelected] = useState([]);

    const [whatYouDo, setPrefWhatYouDo] = useState([]);
    const [whatYouDoSelected, setWhatYouDoSelected] = useState([]);

    const [othersProps, setOthersProps] = useState({});

    // init
    useEffect(() => {
        // if the user didnt fill his phone number at /step1, he will be redirected to the phone form
        if (userPhone === null) return navigate(routes.home);

        loadingOn();
        getUser();
        getBrands();
        getProducts();
        getRegions();
        getDivisions();

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setCoordinates({ latitude: position.coords.latitude, longitude: position.coords.longitude });
                    setLocationAccepted(true);
                    loadingOff();
                },
                (error) => {
                    console.error("Geolocation Error:", error);
                    alert(t("page.signUp.geolocationDenied"));
                    setLocationAccepted(false);
                    loadingOff();
                }
            );
        } else {
            alert(t("page.signUp.geolocationNotSupported"));
            setLocationAccepted(false);
            loadingOff();
        }

    }, []);

    useEffect(() => {
        if (user.brand == 1004 || user.brand == 1456) {
            setOthersProps({
                required: true,
                onInvalid: handleInvalid,
                onInput: handleInvalidInput,
            });
        } else {
            setOthersProps({
                onInvalid: (e) => e.preventDefault(),
            });
        }
    }, [user.brand]);

    const filteredProducts = products.filter((prod) => {
        if (Number(prod.master_id) === Number(user.brand)) {
            return prod;
        }
    });

    const filteredDivisions = divisions.filter((div) => {
        if (Number(div.region_id) === Number(user.region)) {
            return div;
        }
    });

    const filterMultipleSelect = (values, name) => {
        const filteredValues = Object.values(values).map((el) => {
            const valueTranslated = el.split("|");
            return {
                label:
                    i18n.language === "fr"
                        ? valueTranslated[1].trim()
                        : valueTranslated[0].trim(),
                value: el,
            };
        });
        if (!name) {
            return filteredValues;
        } else {
            switch (name) {
                case "social":
                    setSocials(filteredValues);
                    break;
                case "nicotine":
                    setNicotineLocations(filteredValues);
                    break;
                case "whereBuyCigFrom":
                    setWhereBuyCigFrom(filteredValues);
                    break;
                case "setPassionPoint":
                    setValuePassPointSelect(filteredValues);
                    break;
                case "preferredMidia":
                    setPreferredMidia(filteredValues);
                    break;
                case "passionPointSport":
                    setPassionPointSport(filteredValues);
                    break;
                case "passionPointMusic":
                    setPassionPointMusic(filteredValues);
                    break;
                case "passionPointLifeStyle":
                    setPassionPointLifeStyle(filteredValues);
                    break;
                case "whatYouDo":
                    setPrefWhatYouDo(filteredValues);
                    break;
                default:
                    break;
            }
        }
    };

    const getUser = () => {
        const payload = {
            area_code: userPhone[0],
            phone_number: userPhone.slice(1),
        };
        axiosClient
            .post("/user", payload)
            .then((response) => {
                const { error, message, data } = response.data;
                if (error === true) return alert(message);
                else {
                    setUser({
                        ...user,
                        name: data.firstname ?? "",
                        email: data.email ?? "",
                        age: data.age ?? "",
                        gender: data.gender ?? "",
                        brand: data.pref_brand_id ?? "",
                        product: data.pref_product_id ?? "",
                        other: data.pref_nicotine_id ?? "",
                        region: data.pref_region_id ?? "",
                        division: data.division ?? "",
                        tobaccoComm: data.tobaccoComm ?? "",
                        where_buy_cig_from: data.where_buy_cig_from ?? "",
                        pref_where_buy_cig_from: data.pref_where_buy_cig_from ?? "",
                        preferred_midia: data.preferred_midia ?? "",
                        pref_preferred_midia: data.pref_preferred_midia ?? "",
                        passion_point_sport: data.passion_point_sport ?? "",
                        passion_point_music: data.passion_point_music ?? "",
                        passion_point_lifestyle: data.passion_point_lifestyle ?? "",
                        pref_passion_point_sport: data.pref_passion_point_sport ?? "",
                        pref_passion_point_music: data.pref_passion_point_music ?? "",
                        pref_passion_point_lifestyle: data.pref_passion_point_lifestyle ?? "",
                        nicotine_location: data.nicotine_location ?? "",
                        pref_nicotine_location: data.pref_nicotine_location ?? "",
                        what_you_do: data.what_you_do ?? "",
                        pref_what_you_do: data.pref_what_you_do ?? "",
                        passionPoint: data.pref_passion_point ?? ""
                    });
                    filterMultipleSelect(data.social_media, "social");

                    filterMultipleSelect(data.nicotine_location, "nicotine_location");
                    filterMultipleSelect(data.pref_nicotine_location, "pref_nicotine_location");

                    filterMultipleSelect(data.where_buy_cig_from, "where_buy_cig_from");
                    filterMultipleSelect(data.pref_where_buy_cig_from, "pref_where_buy_cig_from");

                    filterMultipleSelect(data.preferred_midia, "preferred_midia");
                    filterMultipleSelect(data.pref_preferred_midia, "pref_preferred_midia");

                    filterMultipleSelect(data.passion_point_sport, "passion_point_sport");
                    filterMultipleSelect(data.pref_passion_point_sport, "pref_passion_point_sport");

                    filterMultipleSelect(data.passion_point_music, "passion_point_music");
                    filterMultipleSelect(data.pref_passion_point_music, "pref_passion_point_music");

                    filterMultipleSelect(data.passion_point_lifestyle, "passion_point_lifestyle");
                    filterMultipleSelect(data.pref_passion_point_lifestyle, "pref_passion_point_lifestyle");

                    filterMultipleSelect(data.what_you_do, "what_you_do");
                    filterMultipleSelect(data.pref_what_you_do, "what_you_do");

                    setSocialsSelected(filterMultipleSelect(data.pref_social_media));

                    setNicotineLocationsSelected(filterMultipleSelect(data.nicotine_location));
                    setNicotineLocations(filterMultipleSelect(data.pref_nicotine_location));

                    setWhereBuyCigFromSelected(filterMultipleSelect(data.where_buy_cig_from));
                    setWhereBuyCigFrom(filterMultipleSelect(data.pref_where_buy_cig_from))

                    setPreferredMidiaSelected(filterMultipleSelect(data.preferred_midia));
                    setPreferredMidia(filterMultipleSelect(data.pref_preferred_midia));

                    setPassionPointSportSelected(filterMultipleSelect(data.passion_point_sport));
                    setPassionPointSport(filterMultipleSelect(data.pref_passion_point_sport));

                    setPassionPointMusicSelected(filterMultipleSelect(data.passion_point_music));
                    setPassionPointMusic(filterMultipleSelect(data.pref_passion_point_music));

                    setPassionPointLifeStyleSelected(filterMultipleSelect(data.passion_point_lifestyle));
                    setPassionPointLifeStyle(filterMultipleSelect(data.pref_passion_point_lifestyle));

                    setPrefWhatYouDo(filterMultipleSelect(data.pref_what_you_do));
                    setWhatYouDoSelected(filterMultipleSelect(data.what_you_do));

                    setPassionPoint(data.passion_point);
                    setValuePassPointSelect(data.pref_passion_point.toLowerCase());

                }
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const getBrands = () => {
        axiosClient
            .get("/brands")
            .then((response) => {
                const { error, message, data } = response.data;
                if (error === true) return alert(message);
                else {
                    setBrands(data);
                }
                loadingOff();
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const getProducts = () => {
        axiosClient
            .get("/products")
            .then((response) => {
                const { error, message, data } = response.data;
                if (error === true) return alert(message);
                else setProducts(data);
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const setPassionPoint = (data) => {
        const currentLang = i18n.language;
        let valueTranslated = [];
        Object.values(data).map((passionPoint) => {
            const valueTranslatedSplit = passionPoint.split('|');
            valueTranslated.push({
                id: passionPoint,
                name: currentLang === "fr" ? valueTranslatedSplit[1].trim() : valueTranslatedSplit[0].trim()
            });
        });
        _setPassionPoint(valueTranslated);
    };

    const setRegions = (data) => {
        const currentLanguage = i18n.language;
        const translatedRegions = data.map((reg) => {
            if (currentLanguage === "fr") {
                return {
                    id: reg.id,
                    name: reg.name_fr,
                    region_id: reg.region_id,
                };
            } else {
                return {
                    id: reg.id,
                    name: reg.name,
                    region_id: reg.region_id,
                };
            }
        });
        _setRegions(translatedRegions);
    };

    const getRegions = () => {
        axiosClient
            .get("/regions")
            .then((response) => {
                const { error, message, data } = response.data;
                if (error === true) return alert(message);
                else setRegions(data);
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const setDivisions = (data) => {
        const currentLanguage = i18n.language;
        const translatedDivisions = data.map((div) => {
            return {
                id: div.name,
                name: currentLanguage === "fr" ? div.name_fr : div.name,
                region_id: div.region_id,
            };
        });
        _setDivisions(translatedDivisions);
    };

    const setOptionPassionPoint = (ev) => {
        if(ev.target.name === 'passionPoint'){
            setValuePassPointSelect(ev.target.value.toLowerCase());
            setValuePassPointOrg(ev.target.value);
        }

    }

    const getDivisions = () => {
        axiosClient
            .get("/divisions")
            .then((response) => {
                const { error, message, data } = response.data;
                if (error === true) return alert(message);
                else setDivisions(data);
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const handleChange = (ev) => {
        setOptionPassionPoint(ev);
        setUser({
            ...user,
            [ev.target.name]:
                ev.target.type === "checkbox"
                    ? ev.target.checked
                    : ev.target.value,
        });
    };

    const formatMultiSelect = (values) => {
        if (values.length === 1) return [values[0].value];
        return values.map(({ value }) => {
            return value;
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (nicotineLocationsSelected.length === 0) {
            document.querySelectorAll('.dropdown-heading')[1].style.border = '1px solid red';
            setTimeout(() => {
                alert(t("page.signUp.required.select") + " nicotine");
            }, 100);
            return;
        }
        if (!locationAccepted) {
            alert(t("page.signUp.locationRequired"));
            return;
        }

        if(whatYouDo.length === 0 || preferredMidia.length === 0){
            return;
        }

        let emptyCount = 0;

        if (passionPointSport.length === 0) emptyCount++;
        if (passionPointMusic.length === 0) emptyCount++;
        if (passionPointLifeStyleSelected.length === 0) emptyCount++;

        if (emptyCount >= 2) {
            return;
        }

        loadingOn();
        const payload = {
            ...user,
            consent,
            terms,
            area_code: userPhone[0],
            phone_number: userPhone.slice(1),
            lang: languages[i18n.language],
            retailer_code: retailerCode,
            social_media: formatMultiSelect(socialsSelected),
            nicotine_location: formatMultiSelect(nicotineLocations),
            where_buy_cig_from: formatMultiSelect(whereBuyCigFrom),
            preferred_midia: formatMultiSelect(preferredMidia),
            passion_point_sport: formatMultiSelect(passionPointSport),
            passion_point_music: formatMultiSelect(passionPointMusic),
            passion_point_lifestyle: formatMultiSelect(passionPointLifeStyle),
            what_you_do: formatMultiSelect(whatYouDo),
            passion_point: valuePassPointOrg,
            ...(coordinates && { coordinates })
        };
        axiosClient
            .put("/user", payload)
            .then(({ data }) => {
                const { error, message } = data;
                if (error === true) return alert(message);
                else navigate(routes.success);
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                loadingOff();
            });
    };

    const handleInvalid = ({ target }) => {
        const { value, checked, validity, type } = target;
        const messageTypes = {
            text: t("page.signUp.required.input"),
            number: t("page.signUp.required.input"),
            "select-one": t("page.signUp.required.select"),
            checkbox: t("page.signUp.required.checkbox"),
        };

        switch (type) {
            case "text":
                if (value.length === 0)
                    target.setCustomValidity(messageTypes[type]);
                break;
            case "number":
                if (value.length === 0)
                    target.setCustomValidity(messageTypes[type]);
                else if (validity.rangeOverflow || validity.rangeUnderflow)
                    target.setCustomValidity(t("page.signUp.valid.age"));
                break;
            case "select-one":
                if (
                    target.dataset.select &&
                    target.dataset.select === "nicotine"
                )
                    target.setCustomValidity(
                        t("page.signUp.required.nicotine")
                    );
                else if (validity.valueMissing)
                    target.setCustomValidity(messageTypes[type]);
                break;
            case "checkbox":
                if (!checked) target.setCustomValidity(messageTypes[type]);
                break;
            case "email":
                if (validity.typeMismatch && !value.includes("@")) {
                    target.setCustomValidity(
                        `${t(
                            "page.signUp.valid.emailFormat.start"
                        )}"${value}"${t("page.signUp.valid.emailFormat.end")}`
                    );
                } else if (validity.typeMismatch && value.includes("@")) {
                    target.setCustomValidity(
                        `${t(
                            "page.signUp.valid.emailIncomplete.start"
                        )}"${value}"${t(
                            "page.signUp.valid.emailIncomplete.end"
                        )}`
                    );
                }
                break;
            default:
                break;
        }

        return true;
    };

    const handleInvalidInput = ({ target }) => {
        if (
            target.type === "number" &&
            (target.validity.rangeOverflow || target.validity.rangeUnderflow)
        ) {
            target.setCustomValidity(t("page.signUp.valid.age"));
        } else {
            target.setCustomValidity("");
        }
    };

    const handleIsTerms = (e) => {
        e.preventDefault();
        setIsTerms(!isTerms);
    };

    return (
        <Fragment>
            {isTerms === false && (
                <form
                    className="form-register"
                    id="form-register"
                    onSubmit={handleSubmit}
                >
                    <div className="row">
                        <img
                            id="logo_secundario"
                            className="logo_form"
                            src={SparrkupLogo}
                            alt="sparrkup logo"
                        />
                    </div>

                    <div className="container-2 content-form col-12">
                        <img
                            className="img-fluid health-banner"
                            src={HealthBanner}
                            alt="health banner"
                        />
                        <div className="row">
                            <div className="col-12 text-center">
                                <img src={BaseImg} alt="base" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 text-center">
                                <label
                                    htmlFor="cellphone"
                                    className="titulo_principal"
                                >
                                    {t("page.signUp.consentsTitle")}
                                </label>
                            </div>
                        </div>
                        <div
                            className="form-group row"
                            style={{ textAlign: "center" }}
                        >
                            <div className="col-sm-12">
                                <div className="form-check text-left">
                                    <label className="form-check-label">
                                        <input
                                            className="form-check-input"
                                            id="consent"
                                            checked={consent}
                                            name="consent"
                                            type="checkbox"
                                            onChange={() =>
                                                setConsent(!consent)
                                            }
                                        />
                                        <label id="term" htmlFor="consent">
                                            {t("page.signUp.consent")}
                                        </label>
                                    </label>
                                </div>
                            </div>
                            <div className="col-sm-12">
                                <div className="form-check text-left">
                                    <label className="form-check-label">
                                        <input
                                            className="form-check-input"
                                            id="consent2"
                                            value="true"
                                            name="consent2"
                                            type="checkbox"
                                            required
                                            checked={terms}
                                            onInvalid={handleInvalid}
                                            onInput={handleInvalidInput}
                                            onChange={() => setTerms(!terms)}
                                        />
                                        <label id="term" htmlFor="consent2">
                                            {t("page.signUp.read")}
                                            <a
                                                href="#"
                                                id="termAndCond"
                                                onClick={handleIsTerms}
                                            >
                                                {" "}
                                                T&C
                                            </a>
                                        </label>
                                    </label>
                                </div>
                            </div>
                        </div>
                        {terms && consent && (
                            <Fragment>
                                <div className="row">
                                    <div className="col-12 text-center">
                                        <label
                                            htmlFor="cellphone"
                                            className="titulo_principal"
                                        >
                                            {t("page.signUp.title")}
                                        </label>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label
                                        htmlFor="inputEmail3"
                                        className="col-sm-4 col-form-label label-form"
                                    >
                                        {t("page.signUp.cellphone")} *
                                    </label>
                                    <div className="col-sm-8">
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={user.cellphone}
                                            disabled
                                        />
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label
                                        htmlFor="name"
                                        className="col-sm-4 col-form-label label-form"
                                    >
                                        {t("page.signUp.name")} *
                                    </label>
                                    <div className="col-sm-8">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="name"
                                            name="name"
                                            placeholder={t("page.signUp.name")}
                                            required
                                            value={user.name}
                                            onChange={handleChange}
                                            onInvalid={handleInvalid}
                                            onInput={handleInvalidInput}
                                        />
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label
                                        htmlFor="email"
                                        className="col-sm-4 col-form-label label-form"
                                    >
                                        {t("page.signUp.email")}
                                    </label>
                                    <div className="col-sm-8">
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="email"
                                            name="email"
                                            value={user.email}
                                            onChange={handleChange}
                                            onInvalid={handleInvalid}
                                            onInput={handleInvalidInput}
                                            placeholder="Email"
                                        />
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label
                                        htmlFor="age"
                                        className="col-sm-4 col-form-label label-form"
                                    >
                                        {t("page.signUp.age")} *
                                    </label>
                                    <div className="col-sm-8">
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="age"
                                            min="21"
                                            max="99"
                                            name="age"
                                            placeholder="Age"
                                            required
                                            value={user.age}
                                            onChange={handleChange}
                                            onInvalid={handleInvalid}
                                            onInput={handleInvalidInput}
                                        />
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label
                                        htmlFor="inputPassword3"
                                        className="col-sm-4 col-form-label label-form"
                                    >
                                        {t("page.signUp.gender.title")} *
                                    </label>
                                    <div className="col-sm-8">
                                        <select
                                            className="form-control"
                                            id="gender"
                                            name="gender"
                                            value={user.gender}
                                            required
                                            onChange={handleChange}
                                            onInvalid={handleInvalid}
                                            onInput={handleInvalidInput}
                                        >
                                            <option></option>
                                            {genders &&
                                                genders.map((el) => {
                                                    return (
                                                        <option
                                                            key={el.id}
                                                            value={el.id}
                                                        >
                                                            {el.name}
                                                        </option>
                                                    );
                                                })}
                                        </select>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label
                                        htmlFor="brand"
                                        className="col-sm-4 col-form-label label-form"
                                    >
                                        {t("page.signUp.brand")} *
                                    </label>
                                    <div className="col-sm-8">
                                        <select
                                            className="form-control"
                                            id="brand"
                                            name="brand"
                                            value={user.brand}
                                            required
                                            onChange={handleChange}
                                            onInvalid={handleInvalid}
                                            onInput={handleInvalidInput}
                                        >
                                            <option></option>
                                            {brands &&
                                                brands.map((el) => {
                                                    return (
                                                        <option
                                                            key={el.id}
                                                            value={el.id}
                                                        >
                                                            {el.name}
                                                        </option>
                                                    );
                                                })}
                                        </select>
                                    </div>
                                </div>

                                <div
                                    className="form-group row"
                                    id="pref-product-div"
                                >
                                    <label
                                        htmlFor="product"
                                        className="col-sm-4 col-form-label label-form"
                                    >
                                        {t("page.signUp.product")}
                                    </label>
                                    <div className="col-sm-8">
                                        <select
                                            className="form-control"
                                            id="product"
                                            name="product"
                                            value={user.product}
                                            onChange={handleChange}
                                        >
                                            <option></option>
                                            {filteredProducts &&
                                                filteredProducts.map((el) => {
                                                    return (
                                                        <option
                                                            key={el.id}
                                                            value={el.id}
                                                        >
                                                            {el.name}
                                                        </option>
                                                    );
                                                })}
                                        </select>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label
                                        htmlFor="other"
                                        className="col-sm-4 col-form-label label-form"
                                    >
                                        {t("page.signUp.nicotine.title")}
                                    </label>
                                    <div className="col-sm-8">
                                        <select
                                            className="form-control"
                                            id="other"
                                            name="other"
                                            data-select="nicotine"
                                            onChange={handleChange}
                                            {...othersProps}
                                            value={user.other}
                                        >
                                            <option></option>
                                            {others &&
                                                others.map((el) => {
                                                    return (
                                                        <option
                                                            key={el.id}
                                                            value={el.id}
                                                        >
                                                            {el.name}
                                                        </option>
                                                    );
                                                })}
                                        </select>
                                    </div>
                                </div>

                                {user.other &&
                                    (user.other === "THPs" ||
                                        user.other === "Vape" ||
                                        user.other === "Modern Oral") && (
                                        <div className="form-group row">
                                            <label
                                                htmlFor="otherBrand"
                                                className="col-sm-4 col-form-label label-form"
                                            >
                                                {t(
                                                    "page.signUp.nicotine.brand"
                                                )}
                                            </label>
                                            <div className="col-sm-8">
                                                <select
                                                    className="form-control"
                                                    id="otherBrand"
                                                    name="otherBrand"
                                                    onChange={handleChange}
                                                    value={user.otherBrand}
                                                >
                                                    <option></option>
                                                    {othersProducts[
                                                        user.other
                                                        ].map((option, index) => (
                                                        <option
                                                            value={
                                                                option ===
                                                                "Autres / Others"
                                                                    ? "Others"
                                                                    : option
                                                            }
                                                            key={option + index}
                                                        >
                                                            {option}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    )}

                                <div className="form-group row">
                                    <label
                                        htmlFor="region"
                                        className="col-sm-4 col-form-label label-form"
                                    >
                                        {t("page.signUp.residence")} *
                                    </label>
                                    <div className="col-sm-8">
                                        <select
                                            className="form-control"
                                            id="region"
                                            name="region"
                                            value={user.region}
                                            required
                                            onChange={handleChange}
                                            onInvalid={handleInvalid}
                                            onInput={handleInvalidInput}
                                        >
                                            <option></option>
                                            {regions &&
                                                regions.map((el) => {
                                                    return (
                                                        <option
                                                            key={el.id}
                                                            value={el.id}
                                                        >
                                                            {el.name}
                                                        </option>
                                                    );
                                                })}
                                        </select>
                                    </div>
                                </div>

                                {user.region && (
                                    <div
                                        className="form-group row"
                                        id="lga-div"
                                    >
                                        <label
                                            htmlFor="division"
                                            className="col-sm-4 col-form-label label-form"
                                        >
                                            {t("page.signUp.division")} *
                                        </label>
                                        <div className="col-sm-8">
                                            <select
                                                className="form-control"
                                                id="division"
                                                name="division"
                                                value={user.division}
                                                required
                                                onChange={handleChange}
                                                onInvalid={handleInvalid}
                                                onInput={handleInvalidInput}
                                            >
                                                <option></option>
                                                {filteredDivisions &&
                                                    filteredDivisions.map(
                                                        (el) => {
                                                            return (
                                                                <option
                                                                    key={el.id}
                                                                    value={
                                                                        el.id
                                                                    }
                                                                >
                                                                    {el.name}
                                                                </option>
                                                            );
                                                        }
                                                    )}
                                            </select>
                                        </div>
                                    </div>
                                )}

                                <div className="form-group row">
                                    <label
                                        htmlFor="social"
                                        className="col-sm-4 col-form-label label-form"
                                    >
                                        {t("page.signUp.socials")}
                                    </label>
                                    <div className="col-sm-8">
                                        <MultiSelect
                                            options={socials}
                                            value={socialsSelected}
                                            onChange={setSocialsSelected}
                                            hasSelectAll={false}
                                            style={{ color: "aqua" }}
                                        />
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label
                                        htmlFor="nicotineLocation"
                                        className="col-sm-4 col-form-label label-form"
                                    >
                                        {t("page.signUp.nicotineLocation")} *
                                    </label>
                                    <div className="col-sm-8">
                                        <MultiSelect
                                            options={nicotineLocationsSelected}
                                            value={nicotineLocations}
                                            onChange={setNicotineLocations}
                                            hasSelectAll={false}
                                            className="multiSel"
                                        />
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label
                                        htmlFor="tobaccoComm"
                                        className="col-sm-4 col-form-label label-form"
                                    >
                                        {t("page.signUp.tobaccoComm")} *
                                    </label>
                                    <div className="col-sm-8">
                                        <select
                                            className="form-control"
                                            id="tobaccoComm"
                                            name="tobaccoComm"
                                            value={user.tobaccoComm}
                                            required
                                            onChange={handleChange}
                                            onInvalid={handleInvalid}
                                            onInput={handleInvalidInput}
                                        >
                                            <option></option>
                                            {tobaccoComms &&
                                                tobaccoComms.map((el) => {
                                                    return (
                                                        <option
                                                            key={el.id}
                                                            value={el.id}
                                                        >
                                                            {el.name}
                                                        </option>
                                                    );
                                                })}
                                        </select>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label
                                        htmlFor="whereBuyCigFrom"
                                        className="col-sm-4 col-form-label label-form"
                                    >
                                        {t("page.signUp.whereBuyCigFrom")} *
                                    </label>

                                    <div className="col-sm-8">
                                        <MultiSelect
                                            options={whereBuyCigFromSelected}
                                            value={whereBuyCigFrom}
                                            onChange={setWhereBuyCigFrom}
                                            hasSelectAll={false}
                                            className="multiSel"
                                        />
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label
                                        htmlFor="preferredMidia"
                                        className="col-sm-4 col-form-label label-form"
                                    >
                                        {t("page.signUp.preferredMidia")} *
                                    </label>

                                    <div className="col-sm-8">
                                        <MultiSelect
                                            options={preferredMidiaSelected}
                                            value={preferredMidia}
                                            onChange={setPreferredMidia}
                                            hasSelectAll={false}
                                            className="multiSel"
                                        />
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label
                                        htmlFor="passionPoint"
                                        className="col-sm-4 col-form-label label-form"
                                    >
                                        {t("page.signUp.passionPoint")} *
                                    </label>
                                    <div className="col-sm-8">
                                        <select
                                            className="form-control"
                                            id="passionPoint"
                                            name="passionPoint"
                                            value={user.passionPoint}
                                            required
                                            onChange={handleChange}
                                            onInvalid={handleInvalid}
                                            onInput={handleInvalidInput}
                                        >
                                            <option></option>
                                            {passionPoint &&
                                                passionPoint.map((el) => {
                                                    return (
                                                        <option
                                                            key={el.id}
                                                            value={el.id}
                                                        >
                                                            {el.name}
                                                        </option>
                                                    );
                                                })}
                                        </select>
                                    </div>
                                </div>

                                { valuePassPointSelect.includes('sports') && (
                                    <div className="form-group row">
                                        <label
                                            htmlFor="passionPointSport"
                                            className="col-sm-4 col-form-label label-form"
                                        >
                                            {t("page.signUp.passionPointSport")} *
                                        </label>

                                        <div className="col-sm-8">
                                            <MultiSelect
                                                options={passionPointSportSelected}
                                                value={passionPointSport}
                                                onChange={setPassionPointSport}
                                                hasSelectAll={false}
                                                className="multiSel"
                                            />
                                        </div>
                                    </div>
                                )}

                                { valuePassPointSelect.includes('music') && (
                                    <div className="form-group row">
                                        <label
                                            htmlFor="passionPointMusic"
                                            className="col-sm-4 col-form-label label-form"
                                        >
                                            {t("page.signUp.passionPointMusic")} *
                                        </label>

                                        <div className="col-sm-8">
                                            <MultiSelect
                                                options={passionPointMusicSelected}
                                                value={passionPointMusic}
                                                onChange={setPassionPointMusic}
                                                hasSelectAll={false}
                                                className="multiSel"
                                            />
                                        </div>
                                    </div>
                                )}

                                { valuePassPointSelect.includes('lifestyle') && (
                                    <div className="form-group row">
                                        <label
                                            htmlFor="passionPointLifeStyle"
                                            className="col-sm-4 col-form-label label-form"
                                        >
                                            {t("page.signUp.passionPointLifeStyle")} *
                                        </label>

                                        <div className="col-sm-8">
                                            <MultiSelect
                                                options={passionPointLifeStyleSelected}
                                                value={passionPointLifeStyle}
                                                onChange={setPassionPointLifeStyle}
                                                hasSelectAll={false}
                                                className="multiSel"
                                            />
                                        </div>

                                    </div>
                                )}


                                <div className="form-group row">
                                    <label
                                        htmlFor="whatYouDo"
                                        className="col-sm-4 col-form-label label-form"
                                    >
                                        {t("page.signUp.whatYouDo")} *
                                    </label>

                                    <div className="col-sm-8">
                                        <MultiSelect
                                            options={whatYouDoSelected}
                                            value={whatYouDo}
                                            onChange={setPrefWhatYouDo}
                                            hasSelectAll={false}
                                            className="multiSel"
                                        />
                                    </div>
                                </div>

                                <div
                                    className="form-group row"
                                    style={{
                                        textAlign: "center",
                                        marginTop: "50px",
                                    }}
                                >
                                    <div className="col-sm-12">
                                        <button
                                            id="buttonSubmit"
                                            type="submit"
                                            className="btn btn_principal submit"
                                            style={{marginTop: "2vw"}}
                                        >
                                            {t("page.signUp.button")}
                                        </button>
                                    </div>
                                </div>
                            </Fragment>
                        )}
                    </div>
                </form>
            )}
            {isTerms === true && (
                <section className="sorry-section">
                    <div className="row">
                        <img
                            id="logo_secundario"
                            src={SparrkupLogo}
                            alt="sparrkup logo"
                        />
                    </div>

                    <div className="row">
                        <div
                            className="container-2 col-12"
                            style={{
                                height:
                                    i18n.language === "fr"
                                        ? "1900px"
                                        : "1750px",
                            }}
                        >
                            <img
                                className="img-fluid health-banner"
                                src={HealthBanner}
                                alt="health banner"
                            />
                            <div className="row">
                                <div className="col-12 text-center">
                                    <img src={BaseImg} alt="base img" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 text-center">
                                    <label
                                        htmlFor="cellphone"
                                        className="titulo_principal"
                                    >
                                        {t("page.signUp.terms.title")}
                                    </label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <label className="terminos">
                                        <Trans i18nKey="page.signUp.terms.description_start" />
                                        <a
                                            target="_blank"
                                            href="https://www.sparrkup.cm/"
                                        >
                                            {t(
                                                "page.signUp.terms.description_link"
                                            )}
                                        </a>
                                        .
                                        <Trans i18nKey="page.signUp.terms.description_end" />
                                    </label>
                                </div>
                            </div>
                            <div className="row">

                                <div className="col-12 text-center">
                                    <button
                                        type="submit"
                                        id="btn_back"
                                        className="btn btn_principal submit"
                                        disabled={!locationAccepted || nicotineLocationsSelected.length === 0}
                                        onClick={handleIsTerms}
                                    >
                                        {t("page.signUp.terms.button")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )}
        </Fragment>
    );
};
