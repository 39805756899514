import { createRef, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useStateContext } from "../../contexts/ContextProvider";

import axiosClient from "../../utils/axios-client";
import routes from "../routes";
import { loadingOff, loadingOn } from "../../utils/common";
import ReCAPTCHA from "react-google-recaptcha";

import SparrkupLogo from "../../assets/images/logo_secundario.png";
import BaseImg from "../../assets/images/base.png";
import HealthBanner from "../../assets/images/health-banner.jpg";

export const PhoneForm = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [phoneNumber, setPhoneNumber] = useState("");
    const formRef = createRef();
    const captchaRef = useRef(null);

    const { setUserPhone } = useStateContext();

    const handleChange = ({ target }) => {
        let finalValue = "";
        if (target.value[0] !== "6") {
            const valuesArray = target.value.split("");
            valuesArray[0] = "6";
            finalValue = valuesArray.join("");
        } else {
            finalValue = target.value;
        }
        setPhoneNumber(finalValue);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        loadingOn();
        
        const token = captchaRef.current.getValue();
        if(!token) {
            alert('Please check the reCAPTCHA');
            loadingOff();
            return;
        }

        const payload = {
            area_code: phoneNumber[0],
            phone_number: phoneNumber.slice(1),
            'g-recaptcha-response': token
        };
        
        axiosClient
            .post("/login", payload)
            .then((response) => {
                const { message, error } = response;
                if (error === true) return alert(message);
                else {
                    setUserPhone(phoneNumber);
                    navigate(routes.registerForm);
                }
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                loadingOff();
            });
    };

    const handleInvalid = ({ target }) => {
        const { value, validity } = target;
        if (value.length === 0) {
            target.setCustomValidity(t("page.phoneForm.required"));
        } else if (validity.patternMismatch === true) {
            target.setCustomValidity(t("page.phoneForm.format"));
        } else {
            target.setCustomValidity("");
            formRef.current.dispatchEvent(
                new Event("submit", { cancelable: true, bubbles: true })
            );
        }
        return true;
    };

    return (
        <form className="form-signin" onSubmit={handleSubmit} ref={formRef}>
            <div className="row">
                <img
                    id="logo_secundario"
                    src={SparrkupLogo}
                    alt="sparrkup logo"
                />
            </div>

            <div className="container-2 col-12">
                <img
                    className="img-fluid health-banner"
                    src={HealthBanner}
                    alt="health banner"
                />
                <div className="row">
                    <div className="col-12 text-center">
                        <img src={BaseImg} alt="base" />
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12 text-center">
                        <label
                            htmlFor="fld_cellphone_number"
                            className="titulo_principal"
                        >
                            {t("page.phoneForm.title")}:
                        </label>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-sm-10">
                        <input
                            type="text"
                            className="form-control"
                            id="fld_cellphone_number"
                            name="fld_cellphone_number"
                            placeholder="612345678"
                            required
                            pattern="\d{9}"
                            maxLength="9"
                            value={phoneNumber}
                            onChange={handleChange}
                            onInvalid={handleInvalid}
                        />
                    </div>
                </div>
                <div className="row mt-3 justify-content-center">
                    <ReCAPTCHA
                        sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                        ref={captchaRef}
                    />
                </div>
                <div className="row">
                    <div className="col-sm-12 text-center">
                        <button
                            type="submit"
                            id="buttonSubmit"
                            className="btn btn_principal submit"
                        >
                            {t("page.phoneForm.button")}
                        </button>
                    </div>
                </div>
            </div>
        </form>
    );
};
